import { Component } from "@/abstracts/component"
import EventBus from "@/abstracts/EventBus"

export class Header extends Component {
	static instance = null

	constructor(config) {
		super(config)

		if (Header.instance) {
			return Header.instance
		}

		Header.instance = this
		this.eventBus = new EventBus()
		this.isStatic = true

		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			burger: this.DOM.root.querySelector(".header__burger"),
		}
	}

	init() {
		this.onClick = this.onClick.bind(this)
		this.addEventListeners()
	}

	addEventListeners() {
		this.DOM.burger.addEventListener("click", this.onClick, false)
	}

	removeEventListeners() {
		this.DOM.burger.removeEventListener("click", this.onClick)
	}

	onClick() {
		this.eventBus.emit("Toggle Nav")
	}

	reset() {
		// If you need to reset any state or re-initialize anything, do it here
		this.removeEventListeners()
		this.addEventListeners()
	}

	resize() {
		// If you need to handle resize events, do it here
		this.reset()
	}

	unmount() {
		this.removeEventListeners()
		this.eventBus.clear("Toggle Nav")
		Header.instance = null
	}
}
