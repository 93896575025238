import { Component } from "@/abstracts/component"
import { wrapElements } from "../tools/DOM"
import gsap from "gsap"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(SplitText)

export class Hero extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			photos: this.DOM.root.querySelectorAll(".hero__photo-container"),
			artefact: this.DOM.root.querySelector(".hero__artefact"),
			svgHeading1: this.DOM.root.querySelector(".svg-heading-1"),
			svgHeading2: this.DOM.root.querySelector(".svg-heading-2"),
			svgHeading3: this.DOM.root.querySelector(".svg-heading-3"),
			text: this.DOM.root.querySelector(".hero__bloc-intro"),
		}
	}

	init() {
		this.createAnimation()
	}

	createAnimation() {
		this.setupSplitText()
		this.setInitialStates()
		this.animateElements()
	}

	setupSplitText() {
		this.DOM.textSplit = new SplitText(this.DOM.text, { type: "lines" })
		this.splitTextInstances.push(this.DOM.textSplit)
		if (this.DOM.textSplit.lines) {
			wrapElements(this.DOM.textSplit.lines, "span", "line-wrap")
		}
	}

	setInitialStates() {
		gsap.set(this.DOM.photos, { scale: 0, rotate: -30, opacity: 0 })
		gsap.set(this.DOM.artefact, { opacity: 0, y: 50 })
		//gsap.set(this.DOM.svgHeading1, { x: -500, opacity: 0 })
		//gsap.set(this.DOM.svgHeading2, { x: 500, opacity: 0 })
		gsap.set(this.DOM.svgHeading3, { y: 25, opacity: 0 })
		gsap.set(this.DOM.textSplit.lines, { yPercent: 100 })
	}

	animateElements() {
		this.tl = gsap.timeline({ delay: 0.5 })

		this.tl
			.from(this.DOM.svgHeading1, {
				opacity: 0,
				x: -500,
				duration: 1.4,
				ease: "expo.out",
			})
			.from(
				this.DOM.svgHeading2,
				{
					opacity: 0,
					x: 200,
					duration: 1.4,
					ease: "expo.out",
				},
				"<"
			)
			.to(this.DOM.svgHeading3, {
				opacity: 1,
				y: 0,
				duration: 0.6,
			})
			.to(this.DOM.photos, {
				opacity: 1,
				scale: 1,
				rotate: 0,
				stagger: 0.15,
				duration: 1,
			})
			.to(
				this.DOM.artefact,
				{
					opacity: 1,
					y: 0,
					duration: 0.5,
				},
				"-=0.6"
			)
			.to(
				this.DOM.textSplit.lines,
				{
					yPercent: 0,
					duration: 0.6,
					stagger: 0.1,
				},
				"-=0.6"
			)
	}

	reset() {
		// Kill existing GSAP animations
		if (this.tl) {
			this.tl.kill()
		}

		// Revert split text
		this.revertSplitText()

		// Reset styles
		gsap.set(
			[
				this.DOM.photos,
				this.DOM.artefact,
				this.DOM.svgHeading1,
				this.DOM.svgHeading2,
				this.DOM.svgHeading3,
				this.DOM.text,
			],
			{ clearProps: "all" }
		)

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance && typeof splitInstance.revert === "function") {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
	}

	unmount() {
		if (this.tl) {
			this.tl.kill()
		}
		this.revertSplitText()
	}
}
