import { Component } from "@/abstracts/component"
import { wrapElements } from "../tools/DOM"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class Columns extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.scrollTriggerInstances = []
		this.animations = [] // New array to track all GSAP animations
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			heading: this.DOM.root.querySelector(".columns__heading"),
			columns: this.DOM.root.querySelectorAll(".columns__column"),
			subheading: [],
			text: [],
		}

		this.DOM.columns.forEach((column) => {
			const subheading = column.querySelector(".columns__column-heading")
			if (subheading) this.DOM.subheading.push(subheading)
			const text = column.querySelector(".columns__column-text p")
			if (text) this.DOM.text.push(text)
		})
	}

	init() {
		this.initAnimations()
	}

	initAnimations() {
		this.createSplitText()
		this.createHeadingAnimation()
		this.createContentAnimation()
	}

	createSplitText() {
		if (this.DOM.heading) {
			this.DOM.headingSplit = new SplitText(this.DOM.heading, { type: "chars" })
			this.splitTextInstances.push(this.DOM.headingSplit)
		}

		if (this.DOM.text.length) {
			this.DOM.textSplit = new SplitText(this.DOM.text, { type: "lines" })
			this.splitTextInstances.push(this.DOM.textSplit)
			wrapElements(this.DOM.textSplit.lines, "div", "line-wrap")
		}
	}

	createHeadingAnimation() {
		if (!this.DOM.headingSplit?.chars) return

		const headingTl = gsap
			.timeline()
			.fromTo(
				this.DOM.headingSplit.chars,
				{ opacity: 0, rotate: -15, y: -50, x: -50 },
				{ opacity: 1, rotate: 0, y: 0, x: 0, duration: 0.8, stagger: 0.06 }
			)

		this.animations.push(headingTl)

		const trigger = ScrollTrigger.create({
			trigger: this.DOM.heading,
			start: "top center",
			animation: headingTl,
		})

		this.scrollTriggerInstances.push(trigger)
	}

	createContentAnimation() {
		if (!this.DOM.subheading.length && !this.DOM.textSplit?.lines) return

		const contentTl = gsap.timeline()

		if (this.DOM.subheading.length) {
			contentTl.fromTo(
				this.DOM.subheading,
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, duration: 1, stagger: 0.1, ease: "expo.out" }
			)
		}

		if (this.DOM.textSplit?.lines) {
			contentTl.fromTo(
				this.DOM.textSplit.lines,
				{ y: 50 },
				{ y: 0, stagger: 0.04, duration: 2, ease: "expo.out" },
				"-=0.5"
			)
		}

		this.animations.push(contentTl)

		const trigger = ScrollTrigger.create({
			trigger: this.DOM.subheading[0] || this.DOM.text[0],
			start: "top center",
			animation: contentTl,
		})

		this.scrollTriggerInstances.push(trigger)
	}

	reset() {
		// Kill all ScrollTrigger instances
		this.scrollTriggerInstances.forEach((trigger) => trigger.kill())
		this.scrollTriggerInstances = []

		// Kill all GSAP animations
		this.animations.forEach((timeline) => timeline.kill())
		this.animations = []

		// Revert split text
		this.revertSplitText()

		// Reset styles for all animated elements
		const elementsToReset = [
			this.DOM.heading,
			...this.DOM.subheading,
			...this.DOM.text,
		].filter(Boolean)

		gsap.set(elementsToReset, {
			clearProps: "all",
		})

		// Reinitialize animations
		this.initAnimations()
	}

	resize() {
		this.reset()
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance?.revert) {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
	}

	unmount() {
		this.scrollTriggerInstances.forEach((trigger) => trigger.kill())
		this.animations.forEach((timeline) => timeline.kill())
		this.revertSplitText()
	}
}
