import { Component } from "@/abstracts/component"
import { wrapElements } from "../tools/DOM"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class ToKnow extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.blocksData = []
		this.animations = {}
		this.init()
	}

	init() {
		this.cacheDOM()
		this.setupAnimations()
		this.bindEvents()
	}

	cacheDOM() {
		this.DOM = {
			...this.DOM,
			heading: this.DOM.root.querySelector(".to-know__heading"),
			blocks: this.DOM.root.querySelectorAll(".to-know__block"),
		}

		this.blocksData = Array.from(this.DOM.blocks).map(
			this.processBlock.bind(this)
		)
	}

	processBlock(block) {
		const icon = block.querySelector(".to-know__icon")
		const text = block.querySelector(".to-know__text")
		const textSplit = new SplitText(text, { type: "lines" })
		this.splitTextInstances.push(textSplit)
		wrapElements(textSplit.lines, "div", "line-wrap")

		return { block, icon, text, textSplit }
	}

	setupAnimations() {
		this.animateHeading()
		this.animateBlocks()
	}

	animateHeading() {
		this.animations.heading = gsap.fromTo(
			this.DOM.heading,
			{ opacity: 0, y: 50 },
			{
				opacity: 1,
				y: 0,
				duration: 1,
				ease: "power3.out",
				scrollTrigger: {
					trigger: this.DOM.heading,
					start: "top bottom-=100px",
				},
			}
		)
	}

	animateBlocks() {
		this.blocksData.forEach((data, index) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: data.block,
					start: "top bottom-=100px",
				},
			})

			tl.fromTo(
				data.icon,
				{ scale: 0, opacity: 0 },
				{ scale: 1, opacity: 1, duration: 0.6, ease: "back.out(1.7)" }
			).fromTo(
				data.textSplit.lines,
				{ opacity: 0, y: 20 },
				{ opacity: 1, y: 0, stagger: 0.06, duration: 0.6 },
				"-=0.3"
			)

			this.animations[`block_${index}`] = tl
		})
	}

	bindEvents() {
		window.addEventListener("resize", this.handleResize.bind(this))
	}

	handleResize() {
		this.revertSplitText()
		this.cacheDOM()
		this.setupAnimations()
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance && typeof splitInstance.revert === "function") {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
		this.blocksData = []
	}

	unmount() {
		ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
		Object.values(this.animations).forEach((animation) => animation.kill())
		this.revertSplitText()
		window.removeEventListener("resize", this.handleResize)
	}
}
