import { Component } from "@/abstracts/component"
import { wrapElements } from "../tools/DOM"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class Boutique extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.scrollTriggerInstance = null
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			heading: this.DOM.root.querySelector(".boutique__heading"),
			text: this.DOM.root.querySelector(".boutique__text > p"),
			textNotP: this.DOM.root.querySelector(".boutique__text > *:not(p)"),
			subheading: this.DOM.root.querySelector(".boutique__subheading"),
			rows: this.DOM.root.querySelectorAll(".boutique__row"),
			artefact: this.DOM.root.querySelector(".boutique__artefact svg"),
		}
	}

	init() {
		this.initAnimation()
	}

	initAnimation() {
		this.setupSplitText()
		this.createTimeline()
	}

	setupSplitText() {
		if (this.DOM.text) {
			this.DOM.textSplit = new SplitText(this.DOM.text, { type: "lines" })
			this.splitTextInstances.push(this.DOM.textSplit)
			wrapElements(this.DOM.textSplit.lines, "span", "line-wrap")
		}
	}

	createTimeline() {
		this.tl = gsap.timeline({
			defaults: { duration: 1, ease: "expo.out" },
		})

		this.tl
			.fromTo(
				[this.DOM.heading, this.DOM.subheading],
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, stagger: 0.6 }
			)
			.fromTo(
				this.DOM.textNotP,
				{ opacity: 0, x: -20 },
				{ opacity: 1, x: 0, stagger: 0.1, duration: 0.8 },
				"-=0.6"
			)
			.fromTo(
				[
					...(this.DOM.textSplit ? this.DOM.textSplit.lines : []),
					...this.DOM.rows,
				],
				{ opacity: 0, y: 20 },
				{ opacity: 1, y: 0, stagger: 0.1, duration: 0.8 },
				"-=0.6"
			)
			.fromTo(
				this.DOM.artefact,
				{ opacity: 0, rotate: -180, scale: 0.6 },
				{ opacity: 1, rotate: 0, scale: 1 },
				"-=0.5"
			)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top center+=100px",
			end: "bottom top",
			once: true,
			animation: this.tl,
		})
	}

	reset() {
		// Kill existing ScrollTrigger and GSAP animations
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Revert split text
		this.revertSplitText()

		// Reset styles
		gsap.set(
			[
				this.DOM.heading,
				this.DOM.subheading,
				this.DOM.textNotP,
				this.DOM.rows,
				this.DOM.artefact,
			],
			{ clearProps: "all" }
		)

		// Reinitialize
		this.initAnimation()
	}

	resize() {
		this.reset()
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance && typeof splitInstance.revert === "function") {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
	}

	unmount() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}
		this.revertSplitText()
	}
}
