import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Btn extends Component {
	constructor(config) {
		super(config)
		this.scrollTriggerInstance = null
		this.init()
	}

	init() {
		this.createAnimation()
	}

	createAnimation() {
		if (!this.DOM.root) {
			console.warn("Trigger element not found")
			return
		}
		this.tl = gsap.timeline({})

		this.tl.fromTo(
			this.DOM.root,
			{
				opacity: 0,
				y: 50,
			},
			{
				opacity: 1,
				y: 0,
				duration: 3,
				ease: "expo.out",
			}
		)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top bottom-=150px",
			animation: this.tl,
		})
	}

	reset() {
		// Kill existing ScrollTrigger and GSAP animation
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Reset styles
		gsap.set(this.DOM.root, { clearProps: "all" })

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	unmount() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}
	}
}
