import { Component } from "@/abstracts/component"
import { wrapElements } from "../tools/DOM"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class HeroSimple extends Component {
	constructor(config) {
		super(config)
		this.animations = {}
		this.init()
	}

	init() {
		this.initCache()
		this.initAnimations()
	}

	initCache() {
		this.DOM.heading = this.DOM.root.querySelector(".hero_simple__heading")
		this.DOM.text = this.DOM.root.querySelector(".hero_simple__text > p")
	}

	initAnimations() {
		this.splitText()
		this.createTimeline()
	}

	splitText() {
		this.textSplit = new SplitText(this.DOM.text, { type: "lines" })
		wrapElements(this.textSplit.lines, "div", "line-wrap")
	}

	createTimeline() {
		this.animations.main = gsap.timeline()

		this.animations.main
			.fromTo(
				this.DOM.heading,
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, duration: 1, ease: "expo.out" }
			)
			.fromTo(
				this.textSplit.lines,
				{ opacity: 0, y: 20 },
				{ opacity: 1, y: 0, stagger: 0.06, duration: 0.6 },
				"-=0.5"
			)

		this.animations.scrollTrigger = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top center",
			animation: this.animations.main,
		})
	}

	resize() {
		this.reset()
		this.init()
	}

	reset() {
		if (this.textSplit) {
			this.textSplit.revert()
		}
		if (this.animations.scrollTrigger) {
			this.animations.scrollTrigger.kill()
		}
		if (this.animations.main) {
			this.animations.main.kill()
		}
	}

	unmount() {
		this.reset()
	}
}
