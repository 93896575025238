import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Contact extends Component {
	constructor(config) {
		super(config)
		this.scrollTriggerInstance = null
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			headingForm: this.DOM.root.querySelector(".contact__heading_form"),
			artefact: this.DOM.root.querySelector(".contact__artefact svg"),
			address: this.DOM.root.querySelector(".contact__address"),
			phone: this.DOM.root.querySelector(".contact__phone"),
			table: this.DOM.root.querySelectorAll(".contact__hours_container *"),
		}
	}

	init() {
		this.createAnimation()
	}

	createAnimation() {
		this.tl = gsap.timeline()

		this.tl
			.fromTo(
				this.DOM.artefact,
				{ opacity: 0, rotate: -180, scale: 0.5 },
				{ opacity: 1, rotate: 0, scale: 1, duration: 1, ease: "expo.out" }
			)
			.fromTo(
				[this.DOM.address, this.DOM.phone],
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, stagger: 0.1, duration: 1, ease: "expo.out" },
				"-=0.8"
			)
			.fromTo(
				this.DOM.table,
				{ opacity: 0, x: -25 },
				{ opacity: 1, x: 0, stagger: 0.03, ease: "expo.out" }
			)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top center",
			animation: this.tl,
		})
	}

	reset() {
		// Kill existing ScrollTrigger and GSAP animation
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Reset styles
		gsap.set(
			[this.DOM.artefact, this.DOM.address, this.DOM.phone, this.DOM.table],
			{ clearProps: "all" }
		)

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	unmount() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}
	}
}
