import Swup from "swup"
import { Parallax } from "@/presets/Parallax"
import EventBus from "@/abstracts/EventBus"
import ComponentsManager from "@/managers/ComponentsManager"
import Scroller from "@/managers/Scroller"
import TransitionStandard from "../transitions/TransitionStandard"
import TransitionHome from "../transitions/TransitionHome"

export default class TransitionManager {
	constructor() {
		this.isInitialLoad = true
		this.init()
		this.events()
	}

	init() {
		this.swup = new Swup({
			containers: ["[data-load]"],
		})
		this.eventBus = new EventBus()
		this.scroller = new Scroller()
		this.parallax = new Parallax()
		this.componentsManager = new ComponentsManager()
		this.transitionStandard = new TransitionStandard(
			this.scroller,
			this.parallax,
			this.componentsManager
		)

		// Check if it's the initial load of the home page
		if (document.body.classList.contains("home") && this.isInitialLoad) {
			this.transitionHome = new TransitionHome(
				this.scroller,
				this.parallax,
				this.componentsManager
			)
			this.transitionHome.in()
			this.isInitialLoad = false
		}
	}

	events() {
		this.swup.hooks.replace("animation:out:await", () => {
			return this.transitionStandard.out()
		})

		this.swup.hooks.replace("animation:in:await", (visit) => {
			return this.transitionStandard.in(visit)
		})

		document.addEventListener(
			"swup:contentReplaced",
			this.handleContentReplaced.bind(this)
		)
		document.addEventListener("click", this.handleLanguageChange.bind(this))

		this.swup.hooks.on("page:view", () => {
			if (this.scroller) {
				this.scroller.start()
			}
		})
	}

	handleContentReplaced() {
		this.reinitializeGravityForms()
		if (this.scroller) {
			this.scroller.init()
			this.scroller.start()
		}
	}

	reinitializeGravityForms() {
		if (typeof gform !== "undefined") {
			gform.initializeOnLoaded()
		}
	}

	handleLanguageChange(event) {
		if (event.target.closest(".lang-item")) {
			event.preventDefault()
			const href = event.target.closest(".lang-item > a").getAttribute("href")
			this.swup.destroy()
			window.location.href = href
		}
	}
}
