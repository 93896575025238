import gsap from "gsap"
import EventBus from "@/abstracts/EventBus"

export default class TransitionStandard {
	constructor(scroller, parallax, componentsManager, eventBus) {
		this.scroller = scroller
		this.parallax = parallax
		this.componentsManager = componentsManager
		this.eventBus = new EventBus()
		this.initCache()
	}

	initCache() {
		this.DOM = {}
		this.DOM.transition = document.querySelector(".transitions")
		this.DOM.leftPane =
			this.DOM.transition.querySelectorAll(".transitions__left")
		this.DOM.rightPane = this.DOM.transition.querySelectorAll(
			".transitions__right"
		)
	}

	out() {
		return new Promise((resolve) => {
			gsap.set(this.DOM.transition, {
				display: "block",
			})
			gsap.set([this.DOM.leftPane, this.DOM.rightPane], {
				yPercent: 100,
			})
			this.scroller.stop()

			this.tl = gsap.timeline({
				defaults: {
					yPercent: 0,
					duration: 0.8,
					ease: "expo.out",
					stagger: 0.2,
				},
				onComplete: () => {
					this.eventBus.emit("Close Nav", 0)
					window.scrollTo(0, 0)
					this.componentsManager.unmountComponents()
					this.scroller.destroy()
					this.parallax.destroy()
					resolve()
				},
			})
			this.tl.to(this.DOM.leftPane, {}).to(this.DOM.rightPane, {}, "<=0.1")
		})
	}

	in(visit) {
		const container = document.querySelector(visit.containers[0])
		return new Promise((resolve) => {
			this.scroller.init()
			this.parallax.init()
			this.componentsManager.getComponents(container)
			this.componentsManager.mountComponents()
			this.tlOut = gsap.timeline({
				defaults: {
					yPercent: -100,
					duration: 0.8,
					ease: "power3.inOut",
					stagger: -0.2,
				},
				onComplete: () => {
					gsap.set(this.DOM.transition, {
						display: "none",
					})
					this.scroller.start()

					this.eventBus.emit("transitionComplete")

					resolve()
				},
			})
			this.tlOut.to(this.DOM.leftPane, {}).to(this.DOM.rightPane, {}, "<")
		})
	}
}
