import { Component } from "@/abstracts/component"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Photos extends Component {
	constructor(config) {
		super(config)
		this.animation = null
		this.init()
	}

	init() {
		this.initCache()
		this.initAnimation()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			container2: this.DOM.root.querySelector(".photos__photo-2-container"),
			image2: this.DOM.root.querySelector(".photos__photo-2-container img"),
			artefact: this.DOM.root.querySelector(".photos__artefact"),
		}
	}

	initAnimation() {
		this.setInitialStates()
		this.createAnimation()
	}

	setInitialStates() {
		gsap.set(this.DOM.container2, {
			overflow: "hidden",
			scale: 0,
			rotation: -45,
			transformOrigin: "center center",
		})

		gsap.set(this.DOM.image2, {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			scale: 2,
			rotation: 45,
			transformOrigin: "center center",
		})

		gsap.set(this.DOM.artefact, {
			opacity: 0,
			y: 50,
		})
	}

	createAnimation() {
		this.animation = gsap.timeline({
			scrollTrigger: {
				trigger: this.DOM.root,
				start: "top center+=100px",
				end: "bottom top",
				toggleActions: "play none none reverse",
			},
		})

		this.animation
			.to(this.DOM.container2, {
				scale: 1,
				rotation: 0,
				duration: 1.2,
				ease: "expo.out",
			})
			.to(
				this.DOM.image2,
				{
					scale: 1,
					rotation: 0,
					duration: 1.2,
					ease: "expo.out",
				},
				0
			)
			.to(
				this.DOM.artefact,
				{
					opacity: 1,
					y: 0,
					duration: 1.2,
					ease: "expo.out",
				},
				"-=0.8"
			)
	}

	unmount() {
		if (this.animation) {
			this.animation.kill()
		}
		ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
	}
}
