import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class CtaExperience extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.scrollTriggerInstance = null
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			artefact: this.DOM.root.querySelector(".cta_experience__artefact"),
			headingDesktop: this.DOM.root.querySelector(
				".cta_experience__heading-desktop svg"
			),
			headingMobile: this.DOM.root.querySelector(
				".cta_experience__heading-mobile svg"
			),
			headingsLength: this.DOM.root.querySelectorAll(".heading-cta-experience"),
			btn: this.DOM.root.querySelector(".cta_experience__btn"),
		}

		this.DOM.headings = Array.from(
			{ length: this.DOM.headingsLength.length },
			(_, i) =>
				this.DOM.headingDesktop.querySelector(
					`.heading-cta-experience-${i + 1}`
				)
		)
	}

	init() {
		this.createAnimation()
	}

	createAnimation() {
		this.setInitialStates()
		this.createTimeline()
	}

	setInitialStates() {
		gsap.set([...this.DOM.headings, this.DOM.btn], {
			opacity: 0,
		})

		gsap.set(this.DOM.headings[0], { xPercent: -50 })
		gsap.set(this.DOM.headings[1], { yPercent: 100 })
		gsap.set(this.DOM.headings[2], { yPercent: -100 })
		gsap.set(this.DOM.headings[3], { xPercent: -50 })
		gsap.set(this.DOM.headings[4], { xPercent: -50 })
		gsap.set(this.DOM.btn, { y: 25 })
	}

	createTimeline() {
		const duration = 1.6
		const stagger = duration - 0.15

		this.tl = gsap.timeline({ paused: true })

		this.tl.fromTo(
			this.DOM.artefact,
			{ opacity: 0, rotate: -180, scale: 0.5 },
			{ opacity: 1, rotate: 0, scale: 1 }
		)

		this.DOM.headings.forEach((heading, index) => {
			this.tl.to(
				heading,
				{
					opacity: 1,
					xPercent: 0,
					yPercent: 0,
					duration: duration,
					ease: "expo.out",
				},
				index === 0 ? 0 : `-=${stagger}`
			)
		})

		this.tl.to(
			this.DOM.btn,
			{
				opacity: 1,
				y: 0,
				duration: 1,
				ease: "expo.out",
			},
			"<"
		)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top center",
			animation: this.tl,
		})
	}

	reset() {
		// Kill existing ScrollTrigger and GSAP animation
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Revert split text
		this.revertSplitText()

		// Reset styles
		gsap.set([...this.DOM.headings, this.DOM.heading, this.DOM.btn], {
			clearProps: "all",
		})

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance && typeof splitInstance.revert === "function") {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
	}

	unmount() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}
		this.revertSplitText()
	}
}
