import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Form extends Component {
	constructor(config) {
		super(config)
		this.scrollTriggerInstance = null
		this.initCache()
		this.init()
		this.bindFormEvents()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			form: this.DOM.root.querySelector("form"),
			labels: this.DOM.root.querySelectorAll("label"),
			inputs: this.DOM.root.querySelectorAll(".ginput_container"),
			textArea: this.DOM.root.querySelectorAll("textarea"),
		}
		this.DOM.fields = [...this.DOM.inputs, ...this.DOM.textArea]
	}

	bindFormEvents() {
		if (this.DOM.form) {
			jQuery(this.DOM.form).on("submit", (e) => {
				// Listen for form submission response
				jQuery(document).on("gform_confirmation_loaded", (event, formId) => {
					// Force page content update
					window.dispatchEvent(new Event("swup:contentReplaced"))
				})
			})
		}
	}

	init() {
		this.createAnimation()
	}

	createAnimation() {
		this.tl = gsap.timeline()

		this.tl
			.fromTo(
				this.DOM.labels,
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, duration: 1, stagger: 0.1, ease: "expo.out" }
			)
			.fromTo(
				this.DOM.fields,
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, duration: 1, stagger: 0.1, ease: "expo.out" },
				"-=0.6"
			)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top center",
			animation: this.tl,
		})
	}

	reset() {
		// Kill existing ScrollTrigger and GSAP animation
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Reset styles
		gsap.set([this.DOM.labels, this.DOM.fields], { clearProps: "all" })

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	unmount() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}
	}
}
