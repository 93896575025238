import gsap from "gsap"
import SplitText from "gsap/SplitText"
import { wrapElements } from "../tools/DOM"
import EventBus from "@/abstracts/EventBus"

export default class TransitionHome {
	constructor(scroller, parallax, componentsManager, eventBus) {
		this.scroller = scroller
		this.parallax = parallax
		this.componentsManager = componentsManager
		this.eventBus = new EventBus()

		this.initCache()
		this.setupAnimation()
	}

	initCache() {
		this.DOM = {}
		this.DOM.root = document.querySelector(".transition-home")
		this.DOM.textLeft = this.DOM.root.querySelector(
			".transition-home__text--left"
		)
		this.DOM.textRight = this.DOM.root.querySelector(
			".transition-home__text--right"
		)
		this.DOM.photoContainer = this.DOM.root.querySelector(
			".transition-home__image-container"
		)
		this.DOM.photo = this.DOM.root.querySelector(".transition-home__image")
	}

	setupAnimation() {
		this.hexagonPath =
			"polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)"
		this.rectanglePath =
			"polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%, 0% 0%)"
		this.rectanglePathHalf =
			"polygon(75% 0%, 100% 0%, 100% 100%, 75% 100%, 50% 100%, 50% 0%)"
		this.rectanglePathHalfVertical =
			"polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0% 100%, 0% 50%)"

		this.DOM.textLeftSplit = new SplitText(this.DOM.textLeft, {
			type: "lines, chars",
		})
		wrapElements(this.DOM.textLeftSplit.lines, "div", "line-wrap")
		this.DOM.textRightSplit = new SplitText(this.DOM.textRight, {
			type: "lines, chars",
		})
		wrapElements(this.DOM.textRightSplit.lines, "div", "line-wrap")

		gsap.set([this.DOM.textLeftSplit.chars, this.DOM.textRightSplit.chars], {
			yPercent: 100,
		})

		gsap.set(this.DOM.photo, {
			clipPath: this.hexagonPath,
			scale: 0,
		})
	}

	createDesktopTimeline() {
		const timeline = gsap.timeline({
			paused: true,
			onComplete: () => {
				this.scroller.start()
				this.parallax.init()
				this.eventBus.emit("transitionComplete")
				this.DOM.root.remove()
			},
		})

		timeline
			.to(this.DOM.root, {
				opacity: 1,
				duration: 0.1,
			})
			.to(this.DOM.photo, {
				scale: 1,
				ease: "expo.out",
				duration: 1.2,
			})
			.to(
				this.DOM.textLeftSplit.chars,
				{
					opacity: 1,
					yPercent: 0,
					stagger: 0.03,
					duration: 0.5,
				},
				"-=.3"
			)
			.to(
				this.DOM.textRightSplit.chars,
				{
					opacity: 1,
					yPercent: 0,
					stagger: 0.03,
					duration: 0.5,
				},
				"-=.4"
			)
			.to(this.DOM.photoContainer, {
				delay: 1,
				width: "100%",
				height: "100%",
				ease: "expo.out",
				duration: 2,
			})
			.to(
				this.DOM.photo,
				{
					clipPath: this.rectanglePath,
				},
				"<"
			)
			.set(
				[this.DOM.textLeft, this.DOM.textRight],
				{
					autoAlpha: 0,
				},
				"-=.1"
			)
			.set(this.DOM.photoContainer, {
				transformOrigin: "right",
				top: 0,
				left: "unset",
				right: 0,
				transform: "translate(0, 0)",
			})
			.to(
				this.DOM.photo,
				{
					clipPath: this.rectanglePathHalf,
					ease: "expo.out",
					duration: 2,
				},
				"-=.4"
			)
			.to(this.DOM.root, {
				autoAlpha: 0,
				duration: 0.4,
			})

		return timeline
	}

	createMobileTimeline() {
		const timeline = gsap.timeline({
			paused: true,
			onComplete: () => {
				this.scroller.start()
				this.parallax.init()
				this.eventBus.emit("transitionComplete")
				this.DOM.root.remove()
			},
		})

		timeline
			.to(this.DOM.root, {
				opacity: 1,
				duration: 0.1,
				//onComplete: () => timeline.pause(),
			})
			.to(this.DOM.photo, {
				scale: 1,
				ease: "expo.out",
				duration: 1,
				//onComplete: () => timeline.pause(),
			})
			.to(
				this.DOM.textLeftSplit.chars,
				{
					opacity: 1,
					yPercent: 0,
					stagger: 0.03,
					duration: 0.5,
					//onComplete: () => timeline.pause(),
				},
				"-=.3"
			)
			.to(
				this.DOM.textRightSplit.chars,
				{
					opacity: 1,
					yPercent: 0,
					stagger: 0.03,
					duration: 0.5,
					//onComplete: () => timeline.pause(),
				},
				"-=.4"
			)
			.to(this.DOM.photoContainer, {
				delay: 1,
				width: "100%",
				height: "100%",
				ease: "expo.out",
				duration: 1.6,
				//onComplete: () => timeline.pause(),
			})
			.to(
				this.DOM.photo,
				{
					clipPath: this.rectanglePath,
				},
				"<"
			)
			.set(
				[this.DOM.textLeft, this.DOM.textRight],
				{
					autoAlpha: 0,
				},
				"-=.8"
			)
			.set(
				this.DOM.photoContainer,
				{
					transformOrigin: "top",
					top: "0",
					bottom: "0",
					left: "0",
					right: "0",
					transform: "translate(0, 0)",
					//onComplete: () => timeline.pause(),
				},
				"<"
			)
			.to(
				this.DOM.photo,
				{
					//clipPath: this.rectanglePathHalfVertical,
					top: "50%",
					height: "50%",
					ease: "expo.out",
					duration: 2,
					//onComplete: () => timeline.pause(),
				},
				"-=1"
			)
			.to(this.DOM.root, {
				autoAlpha: 0,
				duration: 0.4,
				//onComplete: () => timeline.pause(),
			})

		return timeline
	}

	in(visit) {
		const container = visit
			? document.querySelector(visit.containers[0])
			: document.querySelector("[data-load]")

		return new Promise((resolve) => {
			this.scroller.stop()

			const isMobile = window.matchMedia("(max-width: 767px)").matches
			const timeline = isMobile
				? this.createMobileTimeline()
				: this.createDesktopTimeline()

			document.addEventListener("click", () => {
				if (timeline.paused()) {
					timeline.play()
				}
			})

			timeline.play()
		})
	}

	out() {
		return new Promise((resolve) => {
			gsap.set(this.DOM.root, { display: "block" })
			this.scroller.stop()

			gsap.timeline({
				onComplete: () => {
					this.eventBus.emit("Close Nav", 0)
					window.scrollTo(0, 0)
					this.componentsManager.unmountComponents()
					this.scroller.destroy()
					this.parallax.destroy()
					this.DOM.root.remove()
					resolve()
				},
			})
		})
	}
}
