import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import { wrapElements } from "@/tools/DOM"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class TextPhotos extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.animations = []
		this.init()
	}

	init() {
		this.initCache()
		this.initAnimation()
	}

	initCache() {
		this.DOM.sections = this.DOM.root.querySelectorAll(".text_photos__section")
	}

	initAnimation() {
		this.DOM.sections.forEach(this.setupSectionAnimation.bind(this))
	}

	setupSectionAnimation(section) {
		const heading = section.querySelector(".text_photos__heading")
		const textNotP = section.querySelectorAll(".text_photos__text > *:not(p)")
		const text = section.querySelectorAll(".text_photos__text > p")

		let textSplit = null
		if (text.length) {
			textSplit = new SplitText([...text], { type: "lines" })
			this.splitTextInstances.push(textSplit)
			wrapElements(textSplit.lines, "span", "line-wrap")
		}

		this.animateSection(section, heading, textNotP, textSplit)
	}

	animateSection(section, heading, textNotP, textSplit = null) {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				start: "top center+=100px",
				end: "bottom top",
				toggleActions: "play none none none",
				once: true,
			},
		})

		if (heading) {
			tl.fromTo(
				heading,
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, duration: 1, ease: "expo.out" }
			)
		}

		if (textNotP.length) {
			tl.fromTo(
				textNotP,
				{ opacity: 0, x: -20 },
				{ opacity: 1, x: 0, stagger: 0.06, duration: 0.6 },
				"-=0.5"
			)
		}

		if (textSplit) {
			tl.fromTo(
				textSplit.lines,
				{ opacity: 0, y: 20 },
				{ opacity: 1, y: 0, stagger: 0.06, duration: 0.6 },
				"-=0.5"
			)
		}

		this.animations.push(tl)
	}

	resize() {
		this.revertSplitText()
		this.clearAnimations()
		this.initAnimation()
	}

	clearAnimations() {
		this.animations.forEach((animation) => animation.kill())
		this.animations = []
		ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance && typeof splitInstance.revert === "function") {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
	}

	unmount() {
		this.clearAnimations()
		this.revertSplitText()
	}
}
