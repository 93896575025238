import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class CtaCorpo extends Component {
	constructor(config) {
		super(config)
		this.splitTextInstances = []
		this.scrollTriggerInstance = null
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			svg: this.DOM.root.querySelector(".cta_corpo__image svg"),
			heading: this.DOM.root.querySelector(".cta_corpo__heading"),
			btn: this.DOM.root.querySelector(".cta_corpo__btn"),
		}

		// Create array of heading elements
		this.DOM.headings = Array.from({ length: 5 }, (_, i) =>
			this.DOM.svg.querySelector(`.heading-cta-corpo-${i + 1}`)
		)

		// Validate required elements exist
		if (!this.DOM.svg || !this.DOM.heading || !this.DOM.btn) {
			console.warn("Required elements not found in CtaCorpo component")
			return
		}
	}

	init() {
		if (document.readyState === "loading") {
			document.addEventListener("DOMContentLoaded", () =>
				this.createAnimation()
			)
		} else {
			this.createAnimation()
		}
	}

	createAnimation() {
		try {
			this.createSplitText()
			this.setInitialStates()
			this.createTimeline()
		} catch (error) {
			console.error("Error creating animation:", error)
		}
	}

	createSplitText() {
		if (!this.DOM.heading) return
		this.DOM.headingSplit = new SplitText(this.DOM.heading, { type: "chars" })
		this.splitTextInstances.push(this.DOM.headingSplit)
	}

	setInitialStates() {
		const elements = [
			...this.DOM.headings,
			...(this.DOM.headingSplit?.chars || []),
			this.DOM.btn,
		].filter(Boolean)

		gsap.set(elements, { opacity: 0 })

		// Set initial positions
		const positions = [
			{ yPercent: -100 }, // heading 1
			{ xPercent: 100 }, // heading 2
			{ yPercent: -100 }, // heading 3
			{ xPercent: -100 }, // heading 4
			{ yPercent: 100 }, // heading 5
		]

		this.DOM.headings.forEach((heading, index) => {
			if (heading) gsap.set(heading, positions[index])
		})

		if (this.DOM.headingSplit?.chars) {
			gsap.set(this.DOM.headingSplit.chars, { y: -10 })
		}

		if (this.DOM.btn) {
			gsap.set(this.DOM.btn, { y: 25 })
		}
	}

	createTimeline() {
		const duration = 1.6
		const stagger = duration - 0.15

		this.tl = gsap.timeline()

		// Animate headings
		this.DOM.headings.forEach((heading, index) => {
			if (!heading) return

			this.tl.to(
				heading,
				{
					opacity: 1,
					[index % 2 === 0 ? "yPercent" : "xPercent"]: 0,
					duration: duration,
					ease: "expo.out",
				},
				index === 0 ? 0 : `-=${stagger}`
			)
		})

		// Animate split text chars
		if (this.DOM.headingSplit?.chars) {
			this.tl.to(
				this.DOM.headingSplit.chars,
				{
					opacity: 1,
					y: 0,
					duration: 1,
					stagger: 0.04,
					ease: "expo.out",
				},
				"-=1"
			)
		}

		// Animate button
		if (this.DOM.btn) {
			this.tl.to(
				this.DOM.btn,
				{
					opacity: 1,
					y: 0,
					duration: 1,
					ease: "expo.out",
				},
				"<"
			)
		}

		// Create ScrollTrigger
		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top center",
			animation: this.tl,
		})
	}

	reset() {
		// Kill existing ScrollTrigger and GSAP animation
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Revert split text
		this.revertSplitText()

		// Reset styles
		const elements = [
			...this.DOM.headings,
			this.DOM.heading,
			this.DOM.btn,
		].filter(Boolean)

		gsap.set(elements, { clearProps: "all" })

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance?.revert) {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
	}

	unmount() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}
		this.revertSplitText()
	}
}
