import Lenis from "lenis"

/* 
data-parallax='.5'
Values between 0 and 1 will move slower than the scroll speed
Values greater than 1 will move faster than the scroll speed
Negative values will move in the opposite direction
*/

export class Parallax {
	static instance

	constructor() {
		if (Parallax.instance) {
			return Parallax.instance
		}
		Parallax.instance = this

		// Store elements and instance variables
		this.elements = []
		this.destroyed = false

		// Add a speed multiplier to control overall intensity
		this.speedMultiplier = 0.2 // Reduce this value to make the effect more subtle

		// Bind methods
		this.init = this.init.bind(this)
		this.destroy = this.destroy.bind(this)
		this.update = this.update.bind(this)
		this.onScroll = this.onScroll.bind(this)
	}

	init() {
		// Clean up previous instance if it exists
		if (this.elements.length > 0) {
			this.destroy()
		}

		// Reset destroyed flag
		this.destroyed = false

		// Get all elements with data-parallax attribute
		const parallaxElements = document.querySelectorAll("[data-parallax]")
		console.log("Parallax elements found:", parallaxElements.length)

		// Store initial element data
		this.elements = Array.from(parallaxElements).map((element) => {
			const speed = parseFloat(element.dataset.parallax) || 0.5
			const rect = element.getBoundingClientRect()

			return {
				element,
				speed,
				rect,
				initialY: rect.top + window.scrollY,
				translateY: 0,
			}
		})

		// Only set up animation if we have elements
		if (this.elements.length > 0) {
			// Set up RAF for smooth animation
			this.raf = window.requestAnimationFrame(this.update)

			// Add resize listener for responsive behavior
			//window.addEventListener("resize", this.init)
		}
	}

	update() {
		if (this.destroyed) return

		// Request next frame
		this.raf = window.requestAnimationFrame(this.update)

		// Update parallax positions
		this.onScroll()
	}

	onScroll() {
		const scrollY = window.scrollY

		this.elements.forEach(({ element, speed, initialY }) => {
			// Calculate new position with speed multiplier
			const yOffset = (scrollY - initialY) * speed * this.speedMultiplier

			// Apply transform with hardware acceleration
			element.style.translate = `0 ${yOffset}px`
		})
	}

	destroy() {
		this.destroyed = true

		// Clean up RAF
		if (this.raf) {
			window.cancelAnimationFrame(this.raf)
		}

		// Remove event listener
		window.removeEventListener("resize", this.init)

		// Reset elements
		this.elements.forEach(({ element }) => {
			element.style.translate = ""
		})

		// Clear arrays
		this.elements = []
	}

	// Add method to update speed multiplier if needed
	setSpeedMultiplier(value) {
		this.speedMultiplier = value
	}
}
