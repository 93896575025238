import { Component } from "@/abstracts/component"
import gsap from "gsap"

export class Accordions extends Component {
	constructor(config) {
		super(config)

		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM.accordions = this.DOM.root.querySelectorAll(".accordion")
	}

	init() {
		this.closeAllAccordions()
		this.events()
	}

	closeAllAccordions() {
		this.DOM.accordions.forEach((accordion) => {
			const content = accordion.querySelector(".faq__answer")

			accordion.setAttribute("aria-expanded", "false")
			gsap.set(content, { height: 0 })
			content.setAttribute("aria-hidden", "true")
			accordion.classList.remove("is-active")
		})
	}

	events() {
		this.handleAccordionClick = this.handleAccordionClick.bind(this)
		this.DOM.accordions.forEach((accordion) => {
			accordion.addEventListener("click", this.handleAccordionClick)
		})
	}

	handleAccordionClick(e) {
		if (e.target.closest(".faq__question-trigger")) {
			e.preventDefault()
			const accordion = e.currentTarget
			const index = Array.from(this.DOM.accordions).indexOf(accordion)
			this.toggleAccordion(index)
		}
	}

	toggleAccordion(index) {
		const accordion = this.DOM.accordions[index]
		const content = accordion.querySelector(".faq__answer")
		const isExpanded = accordion.getAttribute("aria-expanded") === "true"

		gsap.killTweensOf(content)

		if (isExpanded) {
			this.closeAccordion(accordion, content)
		} else {
			this.openAccordion(accordion, content)
		}
	}

	openAccordion(accordion, content) {
		accordion.setAttribute("aria-expanded", "true")
		content.style.height = "auto"
		const height = content.offsetHeight
		content.style.height = "0px"

		gsap.to(content, {
			height: height,
			duration: 1.2,
			ease: "expo.out",
			onComplete: () => {
				content.style.height = "auto"
			},
		})
		content.setAttribute("aria-hidden", "false")
		accordion.classList.add("is-active")
	}

	closeAccordion(accordion, content) {
		accordion.setAttribute("aria-expanded", "false")
		gsap.to(content, {
			height: 0,
			duration: 0.3,
			ease: "power2.out",
			onComplete: () => {
				content.setAttribute("aria-hidden", "true")
				accordion.classList.remove("is-active")
			},
		})
	}

	reset() {
		// Kill all ongoing GSAP animations
		gsap.killTweensOf(this.DOM.root.querySelectorAll(".faq__answer"))

		// Remove event listeners
		this.DOM.accordions.forEach((accordion) => {
			accordion.removeEventListener("click", this.handleAccordionClick)
		})

		// Reset all accordions to their initial state
		this.closeAllAccordions()

		// Reinitialize the component
		this.init()
	}

	resize() {
		this.reset()
	}

	unmount() {
		this.DOM.accordions.forEach((accordion) => {
			accordion.removeEventListener("click", this.handleAccordionClick)
		})
	}
}
