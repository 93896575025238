import { Component } from "@/abstracts/component"
import EventBus from "@/abstracts/EventBus"
import Scroller from "@/managers/Scroller"
import gsap from "gsap"

export class Nav extends Component {
	static instance = null

	constructor(config) {
		super(config)

		if (Nav.instance) {
			return Nav.instance
		}
		Nav.instance = this

		this.isStatic = true
		this.eventBus = new EventBus()
		this.scroller = new Scroller()
		this.navIsOpened = false
		this.animations = {}

		this.init()
	}

	init() {
		this.initCache()
		this.bindEvents()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			burger: document.querySelector(".header__burger"),
			logo: document.querySelector(".header__logo"),
			logoArtefact: document.querySelectorAll(".logo-artefact"),
			logoText: document.querySelectorAll(".logo-text"),
			menuItems: this.DOM.root.querySelectorAll(".menu-item"),
			languageSwitcher: this.DOM.root.querySelectorAll(".lang-item"),
		}
	}

	bindEvents() {
		this.eventBus.on("Toggle Nav", this.toggleNav.bind(this))
		this.eventBus.on("Open Nav", this.openNav.bind(this))
		this.eventBus.on("Close Nav", (duration) => this.closeNav(duration))
		document.addEventListener("keydown", this.handleKeyDown.bind(this))
	}

	handleKeyDown(event) {
		if (event.key === "Escape") {
			this.closeNav()
		}
	}

	toggleNav() {
		this.navIsOpened ? this.closeNav() : this.openNav()
	}

	openNav() {
		if (this.navIsOpened) return

		this.killAnimations()
		this.scroller.stop()
		this.scroller.initNavScroll(this.DOM.root)

		this.setInitialStyles()
		this.playOpenAnimation()

		this.DOM.burger.classList.add("active")
		this.navIsOpened = true
	}

	closeNav(duration = 0.6) {
		if (!this.navIsOpened) return

		this.killAnimations()
		this.scroller.start()
		this.scroller.destroyNavScroll()

		this.playCloseAnimation(duration)

		this.DOM.burger.classList.remove("active")
		this.navIsOpened = false
	}

	setInitialStyles() {
		gsap.set(this.DOM.root, {
			autoAlpha: 0,
			clipPath: "polygon(100% 92px, 100% 0%, 100% 0%, 100% 92px)",
		})
		gsap.set(this.DOM.logoArtefact, {
			fill: "#e37d3b",
			stroke: "#e37d3b",
		})
		gsap.set(this.DOM.logoText, {
			fill: "#5a7f72",
		})
		gsap.set([this.DOM.menuItems, this.DOM.languageSwitcher], {
			opacity: 0,
			xPercent: -50,
		})
	}

	playOpenAnimation() {
		this.animations.open = gsap.timeline({
			defaults: { ease: "expo.out" },
		})

		this.animations.open
			.to(this.DOM.root, {
				autoAlpha: 1,
				duration: 0.1,
			})
			.to(this.DOM.root, {
				duration: 1,
				clipPath: "polygon(100% 92px, 100% 0%, 0% 0%, 0% 92px)",
			})
			.to(
				this.DOM.logoArtefact,
				{
					fill: "#ffffff",
					stroke: "transparent",
					duration: 0.5,
				},
				"<"
			)
			.to(
				this.DOM.logoText,
				{
					fill: "transparent",
				},
				"<"
			)
			.to(
				this.DOM.root,
				{
					duration: 0.6,
					clipPath: "polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%)",
				},
				"1"
			)
			.to(
				[this.DOM.menuItems, this.DOM.languageSwitcher],
				{
					opacity: 1,
					xPercent: 0,
					duration: 1,
					stagger: 0.1,
				},
				">-0.8"
			)
	}

	playCloseAnimation(duration) {
		this.animations.close = gsap.timeline()

		this.animations.close
			.to(this.DOM.root, {
				duration: duration,
				ease: "power4.in",
				clipPath: "polygon(100% 0%, 100% 0%, 0% 0%, 0% 0%)",
			})
			.to(
				this.DOM.logoArtefact,
				{
					fill: "#e37d3b",
					stroke: "#e37d3b",
					duration: duration,
				},
				"<"
			)
			.to(
				this.DOM.logoText,
				{
					fill: "#5a7f72",
					duration: duration,
				},
				"<"
			)
	}

	killAnimations() {
		gsap.killTweensOf(this.DOM.root)
		if (this.animations.open) this.animations.open.kill()
		if (this.animations.close) this.animations.close.kill()
	}

	unmount() {
		this.killAnimations()
		this.eventBus.clear("Toggle Nav")
		this.eventBus.clear("Open Nav")
		this.eventBus.clear("Close Nav")
		document.removeEventListener("keydown", this.handleKeyDown)
		this.scroller.destroyNavScroll()
	}
}
