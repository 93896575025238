import "../styles/style.scss"

// Import all the shit
import EventBus from "@/abstracts/EventBus"
import Scroller from "@/managers/Scroller"
import { Parallax } from "@/presets/Parallax"
import ComponentsManager from "@/managers/ComponentsManager"
import TransitionManager from "@/managers/TransitionManager"
import { isSPA, DEBUG } from "@/config"
import { Header } from "@/components/Header"

export default class App {
	static instance

	constructor() {
		if (App.instance) {
			return App.instance
		}
		App.instance = this

		this.initCache()
		this.init()
	}

	initCache() {}

	init() {
		this.eventBus = new EventBus()
		this.scroller = new Scroller()
		this.parallax = new Parallax()

		window.componentsManager = this.componentsManager = new ComponentsManager()
		if (isSPA) {
			console.warn("Is SPA!")
			this.parallax.init()
			this.transitionManager = new TransitionManager()
		} else {
		}
	}
}

window.addEventListener("DOMContentLoaded", async () => {
	if (history.scrollRestoration) {
		history.scrollRestoration = "manual"
	}

	window.addEventListener("load", () => {
		setTimeout(() => window.scrollTo(0, 0), 100)
	})

	const app = new App()
	document.querySelector("body").classList.remove("is-invisible")
})
