import { Component } from "@/abstracts/component"
import gsap from "gsap"
import Tempus from "@darkroom.engineering/tempus"
import * as THREE from "three"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import EventBus from "@/abstracts/EventBus"
import { isMobile } from "@/utils/devices"

export class HeroHome extends Component {
	constructor(config) {
		super(config)
		this.DEBUG = false
		this.isAnimationReady = false
		this.enableMouseInteraction = false
		this.isUsingGyro = false
		this.eventBus = new EventBus()
		this.init()
	}

	init() {
		this.initCache()
		this.initAnimation()
		this.initScene()
		this.initCamera()
		this.addLights()
		this.addModel()
		this.initMouseInteraction()
		this.addEventListeners()

		if (this.DEBUG) {
			this.initOrbitControls()
			this.addGUI()
		}

		this.resize()

		// Start Three.js rendering immediately
		this.startRender()

		// Listen for transition completion using EventBus
		this.transitionCompleteHandler = this.eventBus.on(
			"transitionComplete",
			() => {
				this.isAnimationReady = true
				this.startAnimation()
			}
		)

		// If no transition, start animation directly
		if (!document.querySelector(".transition-home")) {
			this.isAnimationReady = true
			this.startAnimation()
		}
	}

	initCache() {
		this.DOM.experience = this.DOM.root.querySelector(
			".hero_home__heading--left"
		)
		this.DOM.experience1 = this.DOM.experience.querySelector(".text1")
		this.DOM.experience2 = this.DOM.experience.querySelector(".text2")
		this.DOM.immersive = this.DOM.root.querySelector(
			".hero_home__heading--right"
		)
		this.DOM.immersive1 = this.DOM.immersive.querySelector(".text1")
		this.DOM.immersive2 = this.DOM.immersive.querySelector(".text2")
		this.DOM.words = this.DOM.root.querySelectorAll(".hero_home__word")
	}

	initAnimation() {
		gsap.set([this.DOM.experience, this.DOM.immersive], { autoAlpha: 0 })
		gsap.set([this.DOM.experience1, this.DOM.experience2], {
			x: -150,
		})
		gsap.set([this.DOM.immersive1, this.DOM.immersive2], {
			x: 150,
		})
		gsap.set(this.DOM.words, { autoAlpha: 0, x: -100 })
	}

	startAnimation() {
		if (!this.isAnimationReady || !this.model) return

		gsap.set(this.model.scale, { x: 0, y: 0, z: 0 })

		const timeline = gsap.timeline()

		timeline
			.to([this.DOM.experience1, this.DOM.experience2], {
				x: 0,
				stagger: 0.1,
				duration: 1.2,
				ease: "power2.out",
			})
			.to(
				this.DOM.experience,
				{
					autoAlpha: 1,
					duration: 1.2,
				},
				"<"
			)
			.to(
				[this.DOM.immersive1, this.DOM.immersive2],
				{
					delay: 0.2,
					x: 0,
					stagger: 0.1,
					duration: 1.2,
					ease: "power2.out",
				},
				"<"
			)
			.to(
				this.DOM.immersive,
				{
					autoAlpha: 1,
					duration: 1.2,
				},
				"<"
			)
			.to(
				this.DOM.words,
				{
					autoAlpha: 1,
					x: 0,
					stagger: 0.1,
					duration: 0.8,
					ease: "expo.out",
				},
				"-=.5"
			)
			.call(() => {
				this.enableMouseInteraction = true
			})
			.to(
				this.model.scale,
				{
					x: 1,
					y: 1,
					z: 1,
					duration: 1.6,
					ease: "expo.out",
				},
				"-=.4"
			)
			.add(() => {
				if (isMobile()) {
					this.initGyroscope()
				}
			}, "-=0.5") // Add a delay after model animation before showing button
	}

	calculateModelSize() {
		// Create a bounding box
		const boundingBox = new THREE.Box3().setFromObject(this.model)

		// Project the center of the model to screen space
		const modelCenter = boundingBox.getCenter(new THREE.Vector3())
		modelCenter.project(this.camera)

		// Convert to screen coordinates
		const screenCenter = {
			x: (modelCenter.x * 0.5 + 0.5) * this.renderer.domElement.clientWidth,
			y: (-(modelCenter.y * 0.5) + 0.5) * this.renderer.domElement.clientHeight,
		}

		this.modelScreenSize = {
			center: screenCenter,
		}

		// Update button position if it exists
		if (this.gyroButton) {
			this.updateButtonPosition()
		}
	}

	updateButtonPosition() {
		if (!this.gyroButton || !this.modelScreenSize) return

		// Position relative to the model's center
		const offsetX = 50 // Adjust this value to move button left/right
		const offsetY = 0 // Adjust this value to move button up/down

		this.gyroButton.style.left = `${this.modelScreenSize.center.x + offsetX}px`
		this.gyroButton.style.top = `${this.modelScreenSize.center.y + offsetY}px`
	}

	showGyroscopeButton() {
		const button = document.createElement("button")
		this.gyroButton = button
		button.className = "hero_home__button"

		gsap.set(button, {
			scale: 0,
			opacity: 0,
			position: "absolute",
			zIndex: 9999,
		})

		// Add Font Awesome icon
		const icon = document.createElement("i")
		icon.className = "fa-regular fa-cube"
		icon.style.color = "var(--color-surface-neutral-lighter)"
		button.appendChild(icon)

		button.addEventListener("click", () => {
			DeviceOrientationEvent.requestPermission()
				.then((permissionState) => {
					if (permissionState === "granted") {
						this.enableGyroscope()
						gsap.to(button, {
							scale: 0,
							opacity: 0,
							duration: 0.3,
							onComplete: () => {
								button.remove()
								this.gyroButton = null
							},
						})
					}
				})
				.catch(console.error)
		})

		this.DOM.root.appendChild(button)
		this.updateButtonPosition()

		// Animate the button in
		gsap.to(button, {
			scale: 1,
			opacity: 1,
			duration: 0.5,
			ease: "back.out",
		})
	}

	initGyroscope() {
		if (typeof DeviceOrientationEvent !== "undefined") {
			if (typeof DeviceOrientationEvent.requestPermission === "function") {
				// iOS devices
				DeviceOrientationEvent.requestPermission()
					.then((permissionState) => {
						if (permissionState === "granted") {
							this.enableGyroscope()
						} else {
							this.showGyroscopeButton()
						}
					})
					.catch(() => {
						// If error checking permission, show button as fallback
						this.showGyroscopeButton()
					})
			} else {
				// Non-iOS devices - no permission needed
				this.enableGyroscope()
			}
		} else {
			console.log("DeviceOrientationEvent is not supported")
		}
	}

	initScene() {
		this.scene = new THREE.Scene()
		this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
		this.renderer.setPixelRatio(window.devicePixelRatio)
		this.updateRendererSize()
		this.renderer.domElement.classList.add("hero_home_cube")
		this.DOM.root.appendChild(this.renderer.domElement)
	}

	initCamera() {
		this.frustumSize = 7
		this.aspect = this.DOM.root.offsetWidth / this.DOM.root.offsetHeight
		this.camera = new THREE.OrthographicCamera(
			(this.frustumSize * this.aspect) / -2,
			(this.frustumSize * this.aspect) / 2,
			this.frustumSize / 2,
			this.frustumSize / -2,
			1,
			1000
		)
		this.camera.position.set(2, 2, 2)
		this.camera.lookAt(0, 0, 0)
	}

	addLights() {
		this.ambientLight = new THREE.AmbientLight(0xffffff, 0.2)
		this.hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.41)
		this.mainLight = new THREE.DirectionalLight(0xffffff, 0.8)
		this.pointLight = new THREE.PointLight(
			new THREE.Color("#ff4000"),
			8.52,
			100
		)

		this.hemiLight.position.set(0, 10, 10)
		this.mainLight.position.set(4.6, 6.8, 5.58)
		this.pointLight.position.set(-0.08, 0.6, -0.2)

		this.scene.add(
			this.ambientLight,
			this.hemiLight,
			this.mainLight,
			this.pointLight
		)

		if (this.DEBUG) {
			this.addLightHelpers()
		}
	}

	addLightHelpers() {
		this.hemiLightHelper = new THREE.HemisphereLightHelper(this.hemiLight, 1)
		this.mainLightHelper = new THREE.DirectionalLightHelper(this.mainLight, 1)
		this.pointLightHelper = new THREE.PointLightHelper(this.pointLight, 1)
		this.scene.add(
			this.hemiLightHelper,
			this.mainLightHelper,
			this.pointLightHelper
		)
	}

	addModel() {
		this.gradientTexture = this.createGradientTexture()
		this.customMaterial = new THREE.MeshStandardMaterial({
			map: this.gradientTexture,
			metalness: 0.631,
			roughness: 0.902,
		})

		this.dracoLoader = new DRACOLoader()
		this.dracoLoader.setDecoderPath(
			"/wp-content/themes/interra/src/scripts/draco/"
		)
		this.loader = new GLTFLoader().setDRACOLoader(this.dracoLoader)

		this.loader.load(
			"/wp-content/themes/interra/src/scripts/models/cube/interra.glb",
			this.onModelLoaded.bind(this),
			this.onProgress,
			this.onError
		)
	}

	onModelLoaded(gltf) {
		this.gltf = gltf
		this.setModel()
	}

	onProgress(xhr) {
		// console.log((xhr.loaded / xhr.total * 100) + '% loaded')
	}

	onError(error) {
		console.error("An error happened", error)
	}

	createGradientTexture() {
		const canvas = document.createElement("canvas")
		canvas.width = canvas.height = 256
		const ctx = canvas.getContext("2d")
		const gradient = ctx.createLinearGradient(0, 0, 256, 256)
		gradient.addColorStop(0, "#87AC8A")
		gradient.addColorStop(1, "#87AC8A")
		ctx.fillStyle = gradient
		ctx.fillRect(0, 0, 256, 256)
		return new THREE.CanvasTexture(canvas)
	}

	setModel() {
		this.model = this.gltf.scene
		this.scene.add(this.model)
		this.model.traverse((child) => {
			if (child.isMesh) child.material = this.customMaterial
		})
		this.model.position.set(0, 0, 0)
		this.model.scale.set(0, 0, 0)
		this.model.rotation.y = -1.578

		// Calculate model bounds
		this.calculateModelSize()

		if (this.isAnimationReady) {
			this.startAnimation()
		}
	}

	initMouseInteraction() {
		this.mouse = new THREE.Vector2()
		this.targetRotation = new THREE.Vector2()
		this.currentRotation = new THREE.Vector2()
	}

	enableGyroscope() {
		console.log("Enabling gyroscope...")
		this.isUsingGyro = true
		window.addEventListener(
			"deviceorientation",
			this.handleDeviceOrientation.bind(this)
		)
		console.log("Gyroscope event listener added")
	}

	handleDeviceOrientation(event) {
		if (!this.enableMouseInteraction || !this.isUsingGyro) return

		// When phone is vertical, beta is around 90 degrees
		// So we'll subtract 90 to make that our new zero point
		const beta = (event.beta || 0) - 45 // Now 0 is vertical
		const gamma = event.gamma || 0 // Left-right tilt stays the same

		// Log first few events to check values
		if (!this.gyroLogged) {
			console.log("Adjusted gyroscope values:", {
				originalBeta: event.beta,
				adjustedBeta: beta,
				gamma: gamma,
			})
			this.gyroLogged = true
		}

		// Convert gyroscope data to normalized values
		const maxTilt = 45 // Maximum tilt angle from vertical

		// Normalize and clamp the values
		const normalizedBeta = Math.max(-maxTilt, Math.min(maxTilt, beta)) / maxTilt
		const normalizedGamma =
			Math.max(-maxTilt, Math.min(maxTilt, gamma)) / maxTilt

		// Update target rotation
		// Adjusted multipliers to control sensitivity
		this.targetRotation.x = normalizedBeta * -0.3 // Front-back tilt
		this.targetRotation.y = normalizedGamma * -0.3 // Left-right tilt
	}

	onMouseMove(event) {
		if (!this.enableMouseInteraction || this.isUsingGyro) return
		this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
		this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
		this.targetRotation.y = -this.mouse.x * 0.3
		this.targetRotation.x = this.mouse.y * 0.3
	}

	render() {
		if (this.DEBUG) {
			this.updateDebug()
		}

		this.updateModelRotation()
		this.renderer.render(this.scene, this.camera)
	}

	updateDebug() {
		if (this.hemiLightHelper) this.hemiLightHelper.update()
		if (this.mainLightHelper) this.mainLightHelper.update()
		if (this.pointLightHelper) this.pointLightHelper.update()
		if (this.controls) this.controls.update()
	}

	updateModelRotation() {
		if (this.model && this.enableMouseInteraction) {
			this.currentRotation.x +=
				(this.targetRotation.x - this.currentRotation.x) * 0.05
			this.currentRotation.y +=
				(this.targetRotation.y - this.currentRotation.y) * 0.05
			this.model.rotation.x = this.currentRotation.x
			this.model.rotation.y = -1.578 + this.currentRotation.y
		}
	}

	startRender() {
		this.updater = Tempus.add(this.render.bind(this), 0)
	}

	updateCameraFrustum() {
		const halfFrustumSize = this.frustumSize / 2
		this.camera.left = -halfFrustumSize * this.aspect
		this.camera.right = halfFrustumSize * this.aspect
		this.camera.top = halfFrustumSize
		this.camera.bottom = -halfFrustumSize
		this.camera.updateProjectionMatrix()
	}

	updateRendererSize() {
		this.renderer.setSize(this.DOM.root.offsetWidth, this.DOM.root.offsetHeight)
	}

	resize() {
		this.frustumSize = this.DOM.root.offsetWidth < 767 ? 15 : 6.6
		this.aspect = this.DOM.root.offsetWidth / this.DOM.root.offsetHeight
		this.updateCameraFrustum()
		this.updateRendererSize()

		// Recalculate model size after resize
		if (this.model) {
			this.calculateModelSize()
		}
	}

	addEventListeners() {
		window.addEventListener("mousemove", this.onMouseMove.bind(this))
		window.addEventListener("resize", this.resize.bind(this))
	}

	initOrbitControls() {
		this.controls = new OrbitControls(this.camera, this.renderer.domElement)
		this.controls.enableDamping = true
		this.controls.dampingFactor = 0.05
	}

	addGUI() {
		const gui = new GUI()
		const cameraFolder = gui.addFolder("Camera")
		cameraFolder.add(this, "frustumSize", 1, 20).onChange(() => {
			this.updateCameraFrustum()
		})
		cameraFolder.close()

		const modelFolder = gui.addFolder("Model")
		modelFolder.add(this.customMaterial, "metalness", 0, 1)
		modelFolder.add(this.customMaterial, "roughness", 0, 1)
		modelFolder.close()

		if (this.controls) {
			const controlsFolder = gui.addFolder("Orbit Controls")
			controlsFolder.add(this.controls, "enabled").name("Enable Controls")
			controlsFolder.add(this.controls, "dampingFactor", 0, 1)
			controlsFolder.add(this.controls, "enableZoom")
			controlsFolder.add(this.controls, "enableRotate")
			controlsFolder.add(this.controls, "enablePan")
			controlsFolder.close()
		}

		const addLightControls = (light, name, helper) => {
			const folder = gui.addFolder(name)
			folder.addColor(light, "color")
			folder.add(light, "intensity", 0, 10)

			if (helper) {
				folder
					.add(helper, "visible")
					.name("Show Helper")
					.onChange((value) => {
						helper.visible = value
					})
			}
			if (light.position) {
				folder.add(light.position, "x", -10, 10)
				folder.add(light.position, "y", -10, 10)
				folder.add(light.position, "z", -10, 10)
			}
			folder.close()
		}

		addLightControls(this.ambientLight, "Ambient Light")
		addLightControls(this.hemiLight, "Hemisphere Light", this.hemiLightHelper)
		addLightControls(this.mainLight, "Directional Light", this.mainLightHelper)
		addLightControls(this.pointLight, "Point Light", this.pointLightHelper)
	}

	removeEventListeners() {
		window.removeEventListener("mousemove", this.onMouseMove.bind(this))
		window.removeEventListener("resize", this.resize.bind(this))
		if (this.isUsingGyro) {
			window.removeEventListener(
				"deviceorientation",
				this.handleDeviceOrientation.bind(this)
			)
		}
		document.removeEventListener("transitionComplete", this.startAnimation)
	}

	unmount() {
		this.isAnimationReady = false
		this.enableMouseInteraction = false
		this.isUsingGyro = false
		this.transitionCompleteHandler?.off()
		this.updater && this.updater()
		if (this.controls) this.controls.dispose()
		this.removeEventListeners()
		this.renderer.dispose()
		this.scene.traverse((object) => {
			if (object.geometry) object.geometry.dispose()
			if (object.material) {
				if (Array.isArray(object.material)) {
					object.material.forEach((material) => material.dispose())
				} else {
					object.material.dispose()
				}
			}
		})
	}
}
