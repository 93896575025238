import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Faq extends Component {
	constructor(config) {
		super(config)
		this.scrollTriggerInstance = null
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			heading: this.DOM.root.querySelector(".faq__heading"),
			accordions: this.DOM.root.querySelectorAll(".accordion"),
		}
	}

	init() {
		this.createAnimation()
	}

	createAnimation() {
		this.tl = gsap.timeline()

		this.tl
			.fromTo(
				this.DOM.heading,
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, duration: 1, ease: "expo.out" }
			)
			.fromTo(
				this.DOM.accordions,
				{ opacity: 0, y: 50 },
				{ opacity: 1, y: 0, duration: 0.8, stagger: 0.1, ease: "expo.out" },
				"-=0.8"
			)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.heading,
			start: "top center",
			animation: this.tl,
		})
	}

	reset() {
		// Kill existing ScrollTrigger and GSAP animation
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Reset styles
		gsap.set([this.DOM.heading, this.DOM.accordions], { clearProps: "all" })

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	unmount() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}
	}
}
