import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export class Footer extends Component {
	constructor(config) {
		super(config)
		//this.isStatic = true
		this.scrollTriggerInstance = null
		this.initCache()
		this.init()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			wrapper: this.DOM.root.querySelector(".wrapper"),
			partners: this.DOM.root.querySelector(".partners"),
			footerBottom: this.DOM.root.querySelector(".footer__bottom"),
			footerBottomChildren: this.DOM.root.querySelectorAll(
				".footer__bottom > *"
			),
		}
	}

	init() {
		this.createAnimation()
	}

	createAnimation() {
		this.tl = gsap.timeline()

		this.tl
			.fromTo(this.DOM.partners, { opacity: 0 }, { opacity: 1, duration: 1.8 })
			.fromTo(
				this.DOM.footerBottom,
				{ scaleX: 0, transformOrigin: "left left" },
				{ scaleX: 1, duration: 1.6, ease: "expo.out" },
				"-=1"
			)
			.fromTo(
				this.DOM.footerBottomChildren,
				{ opacity: 0, x: 50 },
				{ opacity: 1, x: 0, duration: 1, stagger: 0.2, ease: "expo.out" },
				"-=0.6"
			)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top bottom-=100px",
			//toggleActions: "play none none reverse",
			animation: this.tl,
		})
	}

	reset() {
		if (this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
		}
		if (this.tl) {
			this.tl.kill()
		}

		// Reset styles
		gsap.set(
			[
				this.DOM.wrapper,
				this.DOM.partners,
				this.DOM.footerBottom,
				this.DOM.footerBottomChildren,
			],
			{ clearProps: "all" }
		)

		// Reinitialize
		this.createAnimation()
	}

	resize() {
		this.reset()
	}

	unmount() {
		this.reset()
	}
}
