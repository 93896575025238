import { Component } from "@/abstracts/component"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"
import { wrapElements } from "../tools/DOM"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class Origin extends Component {
	constructor(config) {
		super(config)
		this.animations = []
		this.splitTextInstances = []
		this.scrollTriggerInstances = []
		this.init()
	}

	init() {
		this.initCache()
		this.initAnimations()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			svg: this.DOM.root?.querySelector(".origin__image-heading svg"),
			heading: this.DOM.root?.querySelector(".origin__heading"),
			text: this.DOM.root?.querySelectorAll(".origin__text > p"),
			video: this.DOM.root?.querySelector(".origin__video video"),
			subheading: this.DOM.root?.querySelector(".origin__subheading"),
			brands: this.DOM.root?.querySelectorAll(".origin__brand"),
		}

		if (this.DOM.svg) {
			this.DOM.headings = [1, 2, 3]
				.map((i) => this.DOM.svg.querySelector(`.heading-origin-${i}`))
				.filter(Boolean)
		}
	}

	initAnimations() {
		this.splitText()
		this.setInitialStyles()
		this.createAnimations()
	}

	splitText() {
		if (this.DOM.text?.length) {
			const splitInstance = new SplitText(this.DOM.text, { type: "lines" })
			this.splitTextInstances.push(splitInstance)
			wrapElements(splitInstance.lines, "div", "line-wrap")
			this.textSplit = splitInstance
		}
	}

	setInitialStyles() {
		if (this.DOM.headings?.length) {
			gsap.set(this.DOM.headings, { opacity: 0 })
		}
		if (this.DOM.heading && this.DOM.subheading) {
			gsap.set([this.DOM.heading, this.DOM.subheading], { opacity: 0, x: -50 })
		}
		if (this.DOM.brands?.length) {
			gsap.set(this.DOM.brands, { opacity: 0, y: 50 })
		}
		if (this.textSplit?.lines?.length) {
			gsap.set(this.textSplit.lines, { yPercent: 100 })
		}
	}

	createAnimations() {
		this.createSvgAnimation()
		this.createBrandsAnimation()
		this.createVideoTrigger()
	}

	createSvgAnimation() {
		if (!this.DOM.headings?.length) return

		const duration = 1.6
		const stagger = duration - 0.15

		const timeline = gsap.timeline()

		timeline
			.to(this.DOM.headings[0], {
				opacity: 1,
				yPercent: 0,
				duration,
				ease: "expo.out",
			})
			.to(
				this.DOM.headings[1],
				{ opacity: 1, xPercent: 0, duration, ease: "expo.out" },
				`-=${stagger}`
			)
			.to(
				this.DOM.headings[2],
				{ opacity: 1, yPercent: 0, duration, ease: "expo.out" },
				`-=${stagger}`
			)
			.to(
				this.DOM.heading,
				{ opacity: 1, x: 0, duration: 1, ease: "expo.out" },
				"-=0.8"
			)

		if (this.textSplit?.lines) {
			timeline.to(
				this.textSplit.lines,
				{ yPercent: 0, duration: 1, stagger: 0.04, ease: "expo.out" },
				"-=0.4"
			)
		}

		this.animations.push(timeline)

		const trigger = ScrollTrigger.create({
			trigger: this.DOM.root,
			start: "top center",
			animation: timeline,
		})

		this.scrollTriggerInstances.push(trigger)
	}

	createBrandsAnimation() {
		if (!this.DOM.subheading || !this.DOM.brands?.length) return

		const timeline = gsap.timeline()

		timeline
			.to(this.DOM.subheading, { opacity: 1, x: 0, duration: 1 })
			.to(this.DOM.brands, {
				opacity: 1,
				y: 0,
				stagger: 0.1,
				duration: 1,
				ease: "expo.out",
			})

		this.animations.push(timeline)

		const trigger = ScrollTrigger.create({
			trigger: this.DOM.subheading,
			start: "top center",
			animation: timeline,
		})

		this.scrollTriggerInstances.push(trigger)
	}

	createVideoTrigger() {
		if (!this.DOM.video) return

		// Set video properties for better autoplay compatibility
		this.DOM.video.muted = true
		this.DOM.video.playsInline = true
		this.DOM.video.setAttribute("playsinline", "")
		this.DOM.video.setAttribute("muted", "")
		this.DOM.video.load()

		const playVideo = () => {
			if (this.DOM.video.paused) {
				try {
					const playPromise = this.DOM.video.play()

					if (playPromise !== undefined) {
						playPromise.catch((error) => {
							if (error.name === "NotAllowedError") {
								this.showPlayButton()
							}
						})
					}
				} catch (error) {
					this.showPlayButton()
				}
			}
		}

		const trigger = ScrollTrigger.create({
			trigger: this.DOM.video,
			start: "top center",
			onEnter: playVideo,
			onEnterBack: playVideo,
			onLeave: () => {
				if (!this.DOM.video.paused) {
					this.DOM.video.pause()
				}
			},
		})

		this.scrollTriggerInstances.push(trigger)
	}

	showPlayButton() {
		const existingButton = this.DOM.root.querySelector(".video-play-button")
		if (existingButton) return

		const playButton = document.createElement("button")
		playButton.classList.add("video-play-button")
		playButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M8 5v14l11-7z"/>
            </svg>
        `

		playButton.addEventListener(
			"click",
			() => {
				this.DOM.video.play()
				playButton.remove()
			},
			{ once: true }
		)

		this.DOM.video.parentNode.insertBefore(
			playButton,
			this.DOM.video.nextSibling
		)
	}

	reset() {
		// Kill all ScrollTrigger instances
		this.scrollTriggerInstances.forEach((trigger) => trigger.kill())
		this.scrollTriggerInstances = []

		// Kill all GSAP animations
		this.animations.forEach((timeline) => timeline.kill())
		this.animations = []

		// Revert split text instances
		this.revertSplitText()

		// Reset styles for all animated elements
		const elementsToReset = [
			this.DOM.heading,
			this.DOM.subheading,
			...(this.DOM.headings || []),
			...(this.DOM.brands || []),
			...(this.DOM.text ? Array.from(this.DOM.text) : []),
		].filter(Boolean)

		gsap.set(elementsToReset, {
			clearProps: "all",
		})
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance?.revert) {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
		this.textSplit = null
	}

	resize() {
		this.reset()
		this.init()
	}

	unmount() {
		this.reset()
		if (this.DOM.video) {
			this.DOM.video.pause()
			this.DOM.video.removeAttribute("src")
			this.DOM.video.load()
		}
		const playButton = this.DOM.root?.querySelector(".video-play-button")
		if (playButton) playButton.remove()
	}
}
