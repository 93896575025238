import { Component } from "@/abstracts/component"
import { wrapElements } from "../tools/DOM"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import SplitText from "gsap/dist/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText)

export class Todo extends Component {
	constructor(config) {
		super(config)
		this.animations = {}
		this.splitTextInstances = []
		this.init()
	}

	init() {
		this.initCache()
		this.initAnimation()
	}

	initCache() {
		this.DOM = {
			...this.DOM,
			sections: this.DOM.root.querySelectorAll(".todo__section"),
			separatorContainer: this.DOM.root.querySelector(
				".todo__separator-container"
			),
			sectionGrid: this.DOM.root.querySelector(".todo__section-grid"),
			separator: this.DOM.root.querySelector(".todo__separator--animated"),
			sectionTable: this.DOM.root.querySelectorAll(".todo__section-table"),
			smallTableChildren: this.DOM.root.querySelectorAll(
				".todo__small-table *"
			),
			largeTableChildren: this.DOM.root.querySelectorAll(
				".todo__large-table *"
			),
		}
	}

	initAnimation() {
		this.animateSections()
		this.animateTables()
		this.setupSeparator()
		this.animateSeparator()
	}

	initAnimationWithoutResize() {}

	animateSections() {
		this.DOM.sections.forEach((section) => {
			const heading = section.querySelector(".todo__heading")
			const textNotP = section.querySelectorAll(".todo__text > *:not(p)")
			const text = section.querySelectorAll(".todo__text > p")

			if (text.length) {
				const textSplit = new SplitText([...text], { type: "lines" })
				this.splitTextInstances.push(textSplit)
				wrapElements(textSplit.lines, "span", "line-wrap")
				this.animateSection(section, heading, textNotP, textSplit.lines)
			} else {
				this.animateSection(section, heading, textNotP)
			}
		})
	}

	setupSeparator() {
		this.hexagonPath =
			"polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)"
		this.rectanglePath =
			"polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%, 0% 0%)"
		this.DOM.separatorContainerWidth =
			this.DOM.separatorContainer.getBoundingClientRect().width

		gsap.set(this.DOM.separator, {
			//top: 0,
			width: this.DOM.separatorContainerWidth,
			clipPath: this.rectanglePath,
		})

		gsap.set(
			[
				this.DOM.sectionTable,
				this.DOM.smallTableChildren,
				this.DOM.largeTableChildren,
			],
			{
				opacity: 0,
				x: 25,
			}
		)
	}

	animateSeparator() {
		this.animations.separator = gsap.timeline({
			//force3D: true,
			duration: 3,
			scrollTrigger: {
				trigger: this.DOM.sectionTable[0],
				start: "top top+=200px",
				scrub: true,
				pin: this.DOM.separator,
				pinType: "transform",
				pinSpacing: false,
			},
		})

		this.animations.separator
			.from(this.DOM.separator, {
				clipPath: this.hexagonPath,
				top: 0,
				left: `calc(50% - 14rem)`,
				right: "auto",
				maxWidth: "28rem",
				maxHeight: "30rem",
				duration: 1.2,
			})
			.to(this.DOM.separator, {
				clipPath: this.rectanglePath,
				maxWidth: "100%",
				height: "100%",
			})
			.to(this.DOM.separator, {
				width: "100%",
			})
	}

	animateTables() {
		this.DOM.sectionTable.forEach((section) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: section,
					start: "top center",
				},
				defaults: {
					stagger: 0.03,
					ease: "expo.out",
				},
			})

			tl.to(section, {
				opacity: 1,
				x: 0,
				stagger: 0.1,
				ease: "linear",
			})
				.to(this.DOM.smallTableChildren, {
					opacity: 1,
					x: 0,
				})
				.to(this.DOM.largeTableChildren, {
					opacity: 1,
					x: 0,
				})

			this.animations[`table_${section.id}`] = tl
		})

		this.animations.mainTable = gsap
			.timeline({
				scrollTrigger: {
					trigger: this.DOM.sectionTable[0],
					start: "top center",
				},
			})
			.to(this.DOM.sectionTable, {
				opacity: 1,
				x: 0,
				stagger: 0.1,
			})
			.to(this.DOM.largeTableChildren, {
				opacity: 1,
				x: 0,
				ease: "expo.out",
				stagger: 0.04,
			})
	}

	animateSection(section, heading, textNotP, textLines = null) {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				start: "top center+=100px",
				end: "bottom top",
				toggleActions: "play none none none",
				once: true,
			},
		})

		if (heading) {
			tl.fromTo(
				heading,
				{ opacity: 0, x: -50 },
				{ opacity: 1, x: 0, duration: 1, ease: "expo.out" }
			)
		}

		if (textNotP.length) {
			tl.fromTo(
				textNotP,
				{ opacity: 0, x: -20 },
				{ opacity: 1, x: 0, stagger: 0.1, duration: 0.8 },
				"-=0.5"
			)
		}

		if (textLines) {
			tl.fromTo(
				textLines,
				{ opacity: 0, y: 20 },
				{ opacity: 1, y: 0, stagger: 0.1, duration: 0.8 },
				"-=0.5"
			)
		}

		this.animations[`section_${section.id}`] = tl
	}

	reset() {
		try {
			// Kill existing ScrollTrigger instances
			if (this.scrollTriggerInstance) {
				this.scrollTriggerInstance.kill()
			}

			// Kill all animations
			Object.values(this.animations).forEach((animation) => {
				if (animation?.kill) {
					animation.kill()
				}
			})
			this.animations = {}

			// Revert split text
			this.revertSplitText()

			// Reset styles for all elements
			const elements = [
				...Array.from(this.DOM.sections || []),
				this.DOM.separator,
				...Array.from(this.DOM.sectionTable || []),
				...Array.from(this.DOM.smallTableChildren || []),
				...Array.from(this.DOM.largeTableChildren || []),
			].filter(Boolean)

			gsap.set(elements, { clearProps: "all" })

			// Reinitialize
			this.initAnimation()
		} catch (error) {
			console.error("Error during reset:", error)
		}
	}

	resize() {
		this.reset()
	}

	revertSplitText() {
		this.splitTextInstances.forEach((splitInstance) => {
			if (splitInstance?.revert) {
				splitInstance.revert()
			}
		})
		this.splitTextInstances = []
	}

	unmount() {
		try {
			// Kill ScrollTrigger instances
			if (this.scrollTriggerInstance) {
				this.scrollTriggerInstance.kill()
			}

			// Kill all animations
			Object.values(this.animations).forEach((animation) => {
				if (animation?.kill) {
					animation.kill()
				}
			})

			// Revert split text
			this.revertSplitText()

			// Clear any pending timeouts
			if (this.resizeTimeout) {
				clearTimeout(this.resizeTimeout)
			}
		} catch (error) {
			console.error("Error during unmount:", error)
		}
	}
}
