import { Accordions } from "./Accordions"
import { Btn } from "./Btn"
import { Columns } from "./Columns"
import { Contact } from "./Contact"
import { Cursor } from "./Cursor"
import { CtaCorpo } from "./CtaCorpo"
import { CtaExperience } from "./CtaExperience"
import { Boutique } from "./Boutique"
import { Faq } from "./Faq"
import { Footer } from "./Footer"
import { Form } from "./Form"
import { Header } from "./Header"
import { Hero } from "./Hero"
import { HeroSimple } from "./HeroSimple"
import { HeroHome } from "./HeroHome"
import { Origin } from "./Origin"
import { Nav } from "./Nav"
import { Partners } from "./Partners"
import { Photos } from "./Photos"
import { TextPhotos } from "./TextPhotos"
import { Todo } from "./Todo"
import { ToKnow } from "./ToKnow"

export const componentsList = {
	Accordions: Accordions,
	Btn: Btn,
	Columns: Columns,
	Contact: Contact,
	Cursor: Cursor,
	CtaCorpo: CtaCorpo,
	CtaExperience: CtaExperience,
	Boutique: Boutique,
	Faq: Faq,
	Footer: Footer,
	Form: Form,
	Header: Header,
	Hero: Hero,
	HeroSimple: HeroSimple,
	HeroHome: HeroHome,
	Origin: Origin,
	Nav: Nav,
	Partners: Partners,
	Photos: Photos,
	TextPhotos: TextPhotos,
	Todo: Todo,
	ToKnow: ToKnow,
}
