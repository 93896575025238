import { Component } from "@/abstracts/component"
import Glide from "@glidejs/glide"

export class Partners extends Component {
	constructor(config) {
		super(config)
		this.glide = null
		this.resizeTimer = null
		this.init()
	}

	init() {
		this.initCache()
		this.initGlide()
	}

	initCache() {
		this.DOM.container = this.DOM.root.querySelector(".partners__slider")
	}

	initGlide() {
		const glideOptions = {
			type: "carousel",
			//bound: true,
			perView: 6,
			gap: 80,

			autoplay: 3000,
			animationDuration: 500,
			animationTimingFunc: "ease-in-out",
			breakpoints: {
				500: { perView: 1, gap: 40 },
				767: { perView: 2, gap: 50 },
				1000: { perView: 3, gap: 60 },
				1500: { perView: 4, gap: 80 },
				1800: { perView: 5, gap: 80 },
			},
		}

		this.glide = new Glide(this.DOM.container, glideOptions)
		this.glide.mount()
	}

	resize() {
		if (this.resizeTimer) {
			clearTimeout(this.resizeTimer)
		}

		this.resizeTimer = setTimeout(() => {
			if (this.glide && typeof this.glide.update === "function") {
				this.glide.update()
				if (this.glide.index !== undefined) {
					this.glide.go(`=${this.glide.index}`)
				}
			}
		}, 250)
	}

	/* unmount() {
    if (this.glide && typeof this.glide.destroy === "function") {
      this.glide.destroy();
    }
    window.removeEventListener("resize", this.handleResize.bind(this));
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
  } */
}
